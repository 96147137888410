body {
  margin: 0;
  background: #f6f9fb;
  font-size: 13px;
  font-weight: 400 !default;
  font-family:
    Inter,
    Helvetica,
    "sans-serif" !important !default;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-input {
  @apply appearance-none bg-clip-padding rounded-lg block font-medium text-sm m-0 px-6 py-3.5 w-full outline-none min-h-[45px];
  color: rgb(102, 107, 128, 1);
  background-color: rgba(245, 248, 250, 1);
  border-color: rgba(245, 248, 250, 1);
  transition:
    color 0.2s ease 0s,
    background-color 0.2s ease 0s;
}
.form-label {
  @apply box-border inline-block font-medium text-[13px] leading-[20px] mb-2 outline-none;
  color: rgba(70, 78, 95, 1);
}
.form-checkbox {
  @apply appearance-none bg-no-repeat bg-contain border-0 box-border float-none h-9 mr-0 mb-0 -ml-16 -mt-0.5 align-top w-12;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(0, 158, 247, 1);
  background-position: right center;
  transition: background-position 0.15s ease-in-out 0s;
  border-radius: 52px;
  border-color: rgba(0, 158, 247, 1);
}
.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: 0.25rem;
  font-weight: bold;
}
#myGrid {
  flex: 1 1 0px;
  width: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
  background: rgb(224, 224, 218);
  border-radius: 12px;
}
::-webkit-scroller {
  width: 6px;
  background: #000;
  border-radius: 100%;
}
::-webkit-scrollbar-thumb {
  background: #9a9aa6;
  width: 6px;

  border-radius: 100%;
  -webkit-border-radius: 20px;
}

@layer base {
  html {
    font-family: Inter;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
